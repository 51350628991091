<template>
  <div id="app">
    <v-app id="inspire" class="custom_theme">
      <v-main class="pt-2 pt-sm-2 pt-xs-2 pt-md-0 pt-lg-0 pt-xl-0">
          <div>
            <HeaderPos />
          </div>
        <router-view />
        <vue-snotify />
        <!-- <Footer/> -->
        <!-- <Resource /> -->
      </v-main>
    </v-app>
  </div>
</template>

<script>
import HeaderPos from "@/components/HeaderPos.vue";
const instituteHandler = require("@/scripts/instituteHandler.js");
 
export default {
  name: "BanhJiIndex",
  components: {
    HeaderPos,
    // Footer
    // Resource: () => import("@/components/Resource"),
  },
  data: () => ({
    my_part: "",
    show:true
  }),
  created() {
    instituteHandler.init();
  },
  methods: { 
     checkRoute2() {
      let part = window.location.pathname;
      this.my_part = part;
      if (part == "/en/sale" || part == "/en/sale/" || part == "/kh/sale/" || part == "/kh/sale") {
        this.show=false
      } else {
        this.show=true
      }
    },
  },
  computed: {
    part() {
      return window.location.pathname;
    },
  },
  watch: {
    $route: "checkRoute2",
  },
  mounted() {
    this.checkRoute2();
  },
};
</script>
